import React from "react"
// import styles from "../css/donate.module.scss"
import { FormattedMessage } from "react-intl"
import SubLayout from "../components/Sublayout"
import Area from "../components/structural/Area"

function Supply(props) {
  return (
    <SubLayout>
      <Area>
        <div className="container">
          <h1>
            <FormattedMessage id="supply.heading" />
            <p>
              Unser Projekt startete im März 2018 mit der “Jol Primary School
              Anand”. Dort erhalten seitdem 40 Schulmädchen monatlich zwei
              Packungen Binden. Im August 2018 konnten wir unser Projekt auf die
              “School 33, Anand” erweitern und so 50 Mädchen mit Binden
              versorgen. Seit November versorgen wir ebenfalls 400 junge frauen
              der „GJ Patel Secondary School“ monatlich mit Binden.
            </p>
          </h1>
        </div>
      </Area>
    </SubLayout>
  )
}

export default Supply
